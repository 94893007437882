<script lang="ts">
	let extraClass = '';
	export { extraClass as class };
</script>

<div class="card bg-gradient-to-b p-[2px] from-primary-focus to-secondary text-center {extraClass}">
	<div
		class="flex flex-col items-center h-full font-workSans gap-2 py-6 lg:py-12 px-2 lg:px-4 rounded-2xl bg-base-100 bg-gradient-to-b from-base-content/5 to-transparent font-bold"
	>
		<div class="relative textGradient text-xl w-fit">
			<slot name="title" />
		</div>
		<slot name="content" />
	</div>
</div>

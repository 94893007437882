<script lang="ts">
	import { onMount } from 'svelte';

	export let href = '';
	let extraClass = '';
	export let external = false;
	export { extraClass as class };

	let anchor: HTMLAnchorElement;

	onMount(() => {
		if (external) {
			anchor.target = '_blank';
			anchor.rel = 'noreferrer';
		}
	});
</script>

<a
	class="relative {extraClass} w-full border-0 py-4 sm:py-6 text-center max-w-xs h-fit rounded-full bg-gradient-to-r from-secondary-content to-secondary shadow-button hover:from-base-300 hover:to-secondary active:shadow-pressed active:transform-none"
	{href}
	bind:this={anchor}
>
	<slot />
</a>
